import React from 'react';

const FilterSelector = ({ subjects, onTabClick, onFilterSelect, activeTab, acitveLevel }) => {
  return (
    <div className="tabs js-tabs" id="filter-container">
      <div className="tabs__titles-outer-wrapper">
        <ul className="tabs__titles">
          {subjects.map(subject => (
            <li key={subject.subject_id} className={`tabs__title ${activeTab === subject.url_title ? 'is-active' : ''}`} onClick={() => onTabClick(subject.url_title)}>
              <a>{subject.title} ({subject.results_count})</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="tabs__content">
        {subjects.map(subject => (
          <div key={subject.subject_id} className={`tabs__panel ${activeTab === subject.url_title ? 'is-active' : ''}`} id={`${subject.url_title}-tab`}>
            <b className="search-filter-button__title">{subject.title}</b>
            <div>
              {subject.levels.map(level => (
                <div key={level.level_id} className="search-filter-button">
                  <a className={`button-gray-light button-medium list-level__link--${level.level_color} ${acitveLevel === level.level_id ? 'is-current' : ''}`} onClick={() => onFilterSelect(subject, level)}>
                    {level.level_title} ({level.level_results})
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterSelector;