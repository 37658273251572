import React from "react";
import ResultItem from "./ResultItem";

const Topic = ({topic, selectedContentType}) => {
  return <li key={topic.id} className="search-topics__item box-panel box-panel--green">
      <div className="box-feature-content__headline">
        <h5 className="h4 vertical-align-middle" dangerouslySetInnerHTML={{ __html: topic.title }}></h5>
        <a className="button-green button-small" href={`/${topic.path}`} data-tracking-category="search" data-tracking-action="topic_result_clicked" data-tracking-label={topic.title} data-amplitude-events={`{"search_result_clicked":{"content_type":"topic","profile_type":"student","profile_id":1413092,"profile_level":1}}`}>
          zur Themenseite
        </a>
      </div>
      <div className="search-result-list">
        {topic.videos.map((video) => (
          <ResultItem
            key={video.id}
            item={video}
            selectedContentType={selectedContentType}
            topic={topic}
          />
        ))}
      </div>
    </li>
};

export default Topic;