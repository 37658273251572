import React from 'react';

const SubjectQuickFilter = ({ subjects, selectedSubjectId, onSubjectClick }) => {
  return (
    <div>
      <h2 className="h3">Select a Subject</h2>
      <ul className="cards-list grid-x grid-margin-x">
        {subjects.map(subject => (
          <li key={subject.subject_id} className="cards-list__item cell small-6 medium-4 large-3">
            <div
              className={`card card--borderless subject-filter-card ${selectedSubjectId === subject.subject_id ? 'active' : ''}`}
              data-subject-id={subject.subject_id}
              onClick={() => onSubjectClick(subject)}
            >
              <div className="card-section">
                <i className={`subject-filter-card__icon sprites-subjects-32 sprites-subjects-64--${subject.url_title}`}></i>
                <b className="subject-filter-card__text">{subject.title}</b>
                <span className="subject-filter-card__text">{subject.results_count} results</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubjectQuickFilter;