import React from 'react';

const SearchForm = ({searchTerm, handleInputChange}) => {
  return (
    <div className="grid-column-8 search-form-input">
      <div id="instant-search-form" className="search-form">
        <div id="search-input-wrapper">
          <input type="search" value={searchTerm} onChange={handleInputChange} placeholder="Suchbegriff eingeben…" id="search_term" className="search-form__input" autoComplete="off" autoFocus=""/>
        </div>
        <div id="search-state-helpers">
          <button className="clear-icon sprites-search sprites-search--close" id="search-input-clear"></button>
        </div>
      </div>
    </div>
  )
};
export default SearchForm;