import React from 'react';

const ResultItem = ({item, topic, selectedContentType}) => {
  if (selectedContentType === 'worksheets' && !item.worksheet_url) return null;
  const getSubject = (topic) => {
    return topic.path.split("/")[0];
  };
  const getContentUrl = (topic, item) => {
    switch (selectedContentType) {
      case 'videos': return `/${getSubject(topic)}/videos/${item.url_title}?launchpad=video`;
      case 'exercises': return `/${getSubject(topic)}/videos/${item.url_title}?launchpad=test`;
      case 'worksheets': return item.worksheet_url;
      default: return '';
    }
  };

  const getContentTypeIcon = (contentType) => {
    switch (contentType) {
      case 'videos': return 'icon--video green-gradient';
      case 'exercises': return 'icon--exercise yellow-gradient';
      case 'worksheets': return 'sprites-feature-types--worksheets';
      default: return '';
    }
  };

  const formatTime = (duration_in_seconds) => {
    let minutes = Math.floor(duration_in_seconds / 60);
    let hours;
    if (minutes > 60) {
      hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
    }
    let seconds = duration_in_seconds % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (hours) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  return (
    <div key={item.id} className="search-result-list__item search-result-list__item--bookmarkable">
      <a className="search-result-list__item-link" href={getContentUrl(topic, item)}>
        <div className="search-result-list__item-thumbnail">
          {selectedContentType === 'worksheets' && (
            <i className="icon-videos-worksheet sprites-layouts-worksheets_icon">
              <img src={item.image_url} alt="" />
            </i>
          )}
          {(selectedContentType === 'videos' || selectedContentType === 'exercises') && (
            <>
              <img className="search-result-list__item-image" src={item.image_url} alt={item.title} />
              <i className={`${getContentTypeIcon(selectedContentType)} icon icon--small search-result-list__item-icon white`}></i>
            </>
          )}
        </div>
        <div className="search-result-list__item-description">
          <div className="h5 search-result-list__item-title" dangerouslySetInnerHTML={{ __html: item.title }}></div>
          {selectedContentType === 'videos' && (
            <div className="grid-x search-result-list__item-meta-details">
              {item.video_level_text && (
                <div className="video-levels">
                  <span className="video-levels__text">{item.video_level_text}</span>
                </div>
              )}
              <div className="video-icons">
                <div className="search-result-list__item-meta-detail">
                  <i className="icon icon--small icon--video-duration"></i>
                  <span>{formatTime(item.duration)}</span>
                </div>
                <div className="search-result-list__item-meta-detail">
                  <i className="icon icon--exercise icon--small"></i>
                  <span>Interaktive Übung</span>
                </div>
                {item.worksheet_url && (
                  <div className="search-result-list__item-meta-detail">
                    <i className="icon icon--small icon--worksheet"></i>
                    <span>Arbeitsblätter</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </a>
    </div>
  );
}

export default ResultItem;