import React from "react";
import Topic from "./Topic";

const Subject = ({subject, groupedTopics, selectedContentType}) => {

  if (!subject) return null;
  const topics = groupedTopics[subject.url_title];
  if (!topics) return null;
  console.log("url_title", subject)
  return (
    <div key={subject.subject_id} className="subject-group">
      <div className="subject-box">
        <h3 className="h4 subject-box__header">
          <i className={`sprites-subjects-64--${subject.url_title} subject-box__icon`}></i>
          <b className="subject-box__title" dangerouslySetInnerHTML={{ __html: subject.title }}></b>
          <span className="subject-box__results-count">({subject.results_count} Ergebnisse)</span>
        </h3>
        <div className="search-topics box-feature-content">
          <h4 className="subject-box__headline">
            <span className="show-for-medium">Ergebnisse in Themen, Videos, Lerntexten und Übungen</span>
            <span className="show-for-small-only">Themen, Videos, Lerntexten und Übungen</span>
          </h4>
          <ul className="search-topics__list">
            {topics?.map((topic) => (
              <Topic
                key={topic.id}
                topic={topic}
                selectedContentType={selectedContentType}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subject;