import react, {useState} from 'react';
import React from "react";

const ContentTypeFilter = ({selectedContentType, handleContentTypeChange}) => {
  const [hoveredCard, setHoveredCard] = useState(null); // State to track hovered card

  return <div id="content-type-filter">
      <ul className="cards-list grid-margin-x grid-x">
        <li className="cards-list__item cell large-2 medium-3 small-4">
          <div
            className={`card card--borderless content-filter-card ${hoveredCard === 'videos' ? 'on-touch-hover' : ''} ${selectedContentType === 'videos' ? 'active' : ''}`}
            onMouseEnter={() => setHoveredCard('videos')} onMouseLeave={() => setHoveredCard(null)}
            onClick={() => handleContentTypeChange('videos')}>
            <div className="card-section">
              <i className="sprites-feature-types--videos_and_learning_texts"></i>
              <span className="content-filter-card__text">Videos</span>
            </div>
          </div>
        </li>
        <li className="cards-list__item cell large-2 medium-3 small-4">
          <div
            className={`card card--borderless content-filter-card ${hoveredCard === 'exercises' ? 'on-touch-hover' : ''} ${selectedContentType === 'exercises' ? 'active' : ''}`}
            onMouseEnter={() => setHoveredCard('exercises')} onMouseLeave={() => setHoveredCard(null)}
            onClick={() => handleContentTypeChange('exercises')}>
            <div className="card-section">
              <i className="sprites-feature-types--exercises"></i>
              <span className="content-filter-card__text">Übungen</span>
            </div>
          </div>
        </li>
        <li className="cards-list__item cell large-2 medium-3 small-4">
          <div
            className={`card card--borderless content-filter-card ${hoveredCard === 'worksheets' ? 'on-touch-hover' : ''} ${selectedContentType === 'worksheets' ? 'active' : ''}`}
            onMouseEnter={() => setHoveredCard('worksheets')} onMouseLeave={() => setHoveredCard(null)}
            onClick={() => handleContentTypeChange('worksheets')}>
            <div className="card-section">
              <i className="sprites-feature-types--worksheets"></i>
              <span className="content-filter-card__text">Arbeitsblätter</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
};

export default ContentTypeFilter;