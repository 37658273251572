import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Import the CSS file
import FilterTabs from './components/FilterTabs';
import SubjectQuickFilter from './components/SubjectQuickFilter';
import FilterButton from './components/FilterButton';
import FilterSelector from './components/FilterSelector';
import SearchForm from "./components/SearchForm";
import ContentTypeFilter from "./components/ContentTypeFilter";
import SearchResults from "./components/SearchResults";

const SearchPage = ({ mainHeader, searchConfig }) => {
  const { queryDelay, levelRegexString, subjectsUrl, isTestEnv } = searchConfig;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContentType, setSelectedContentType] = useState("videos"); // Default content type
  const [topics, setTopics] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(-1); // State to track selected subject
  const [selectedLevel, setselectedLevel] = useState(-1); // State to track selected subject

  const [showFilters, setShowFilters] = useState(false); // Show/Hide filters
  const [activeTab, setActiveTab] = useState(null); // Active tab in filters

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qParam = urlParams.get('q');
    if (qParam) {
      setSearchTerm(qParam);
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        performSearch();
      }, isTestEnv ? 0 : queryDelay);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setTopics([]);
      setSubjects([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchSubjects();
  }, [selectedSubject]);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get('/api/search/facets.json', { params: { search_term: searchTerm, subject: selectedSubject.subject_id, level_id: selectedLevel.level_id } });
      if (response.data && response.data.subjects) {
        setSubjects(response.data.subjects);
        fetchTopics();
      }
    } catch (error) {
      console.error("Error fetching facets", error);
    }
  };

  const fetchTopics = async () => {
    try {
      const response = await axios.get('/api/search/content', { params: { search_term: searchTerm, subject: selectedSubject.subject_id, level_id: selectedLevel.level_id } });
      if (response.data && response.data.topics) {
        setTopics(response.data.topics);
        setSearchMessage("");
      } else {
        setTopics([]);
        setSearchMessage("No results found.");
      }
    } catch (error) {
      console.error("Error fetching search results", error);
      setSearchMessage("Error fetching search results.");
    }
  };

  const performSearch = async () => {
    fetchSubjects(); // Fetch facets for subjects grouping
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleContentTypeChange = (contentType) => {
    setSelectedContentType(contentType);
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject);
  };

  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const handleTabClick = (subjectUrlTitle) => {
    setActiveTab(subjectUrlTitle);
  };

  const handleFilterSelect = (subject, level) => {
    // Apply the filter and perform the search
    console.log("Selected filter", subject, level);
    if(level.level_id === selectedLevel.level_id && subject.subject_id === selectedSubject.subject_id){
      setselectedLevel(-1);
      setSelectedSubject(-1);
    } else {
      setselectedLevel(level);
      setSelectedSubject(subject);
    }
    performSearch();
    setShowFilters(false);
  };

  return (
    <div className="search-page">
      <div className="page-content">
        <header className="search-content-header">
          <div className="grid-row">
            <div className="grid-column-12">
              <h1 className="search-header">Suche</h1>
            </div>
          </div>
          <div className="grid-row search-form-container">
            <SearchForm
              searchTerm={searchTerm}
              handleInputChange={handleInputChange}
            />
            <FilterButton
              onFilterClick={handleFilterButtonClick}
              buttonText={selectedLevel !== -1 ? `${selectedSubject.title}, ${selectedLevel.level_title}` : ""}
            />
          </div>
          <div className="grid-row">
            <div className="grid-column-12">
              {showFilters && (
                <FilterSelector
                  subjects={subjects}
                  onTabClick={handleTabClick}
                  onFilterSelect={handleFilterSelect}
                  activeTab={activeTab}
                  acitveLevel={selectedLevel.level_id}
                />
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column-12">
              <ContentTypeFilter
                selectedContentType={selectedContentType}
                handleContentTypeChange={handleContentTypeChange}
              />
            </div>
          </div>
        </header>
        <div className="search-results">
          {searchMessage && <div className="search-message">{searchMessage}</div>}
          <SearchResults
            topics={topics}
            searchTerm={searchTerm}
            subjects={subjects}
            selectedSubject={selectedSubject}
            handleSubjectChange={handleSubjectChange}
            selectedContentType={selectedContentType}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;