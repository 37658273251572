import SubjectQuickFilter from "./SubjectQuickFilter";
import React from "react";
import Subject from "./Subject";

const SearchResults = ({topics, searchTerm, subjects, selectedSubject, handleSubjectChange, selectedContentType}) => {
  const getSubject = (topic) => {
    return topic.path.split("/")[0];
  };
  const groupBySubject = (topics) => {
    if (!topics) return {};
    console.log(topics)
    const grouped = topics.reduce((acc, topic) => {
      const subject = getSubject(topic); // Assuming topic.subject is the subject field
      if (!acc[subject]) {
        acc[subject] = [];
      }
      acc[subject].push(topic);
      return acc;
    }, {});
    return grouped;
  };

  const groupedTopics = groupBySubject(topics);
  console.log("groupedTopics", groupedTopics)

  return (
    <div id="search-results">
      <div className="grid-row">
        <div className="grid-column-12">
          <div id="search-message">
            {searchTerm === "" && (
              <div className="search-message search-message--">
                <div className="search-message__header search-message__header--default">
                  <i className="search-message__girl-icon"></i>
                  <p className="search-message__header-text">
                    <span className="show-for-medium"> Hier ein paar Tipps, <b>wie du schnell das findest</b>, was du suchst! </span>
                    <span className="show-for-small-only"> Hier ein paar Tipps, <b>wie du schnell das findest</b>, was du suchst! </span>
                  </p>
                </div>
                <div className="search-message__tips-panel">
                  <a className="hide-tips is-hidden js-hide-tips">
                    <i className="sprites-search sprites-search--close"></i>
                  </a>
                  <ul className="info-bullet-list">
                    <li><span>Benutze einfache Suchbegriffe und beginne am besten mit einem einzelnen Suchwort</span> </li>
                    <li><span>Verwende die Fachbegriffe aus deinem Unterricht oder Schulbuch</span></li>
                  </ul>
                </div>
              </div>
            )}
            {subjects.length === 0 && searchTerm !== "" && (
              <div className="search-message search-message--">
                <div className="search-message__header search-message__header--no_results">
                  <i className="search-message__girl-icon"></i>
                  <p className="search-message__header-text">
                    <span className="show-for-medium"> Leider gibt es keine Ergebnisse zu <br /><b>„{searchTerm}“</b> </span>
                    <span className="show-for-small-only"> Leider gibt es keine Ergebnisse zu <br /><b>„{searchTerm}“</b> </span>
                  </p>
                </div>
                <div className="search-message__tips-panel">
                  <a className="hide-tips is-hidden js-hide-tips">
                    <i className="sprites-search sprites-search--close"></i>
                  </a>
                  <ul className="info-bullet-list">
                    <li><span>Überprüfe die Rechtschreibung zu deinem Suchbegriff</span></li>
                    <li><span>Benutze ein allgemeineres Suchwort oder versuche es mit einem Synonym</span></li>
                    <li><span>Auf unseren <a href="/schulfaecher">Fächerseiten</a> findest du für jedes Fach und jede Klassenstufe eine Übersicht aller auf sofatutor verfügbaren Inhalte</span> </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          {subjects.length > 0 && (
            <div id="subject-quick-filter">
              <SubjectQuickFilter
                subjects={subjects}
                selectedSubjectId={selectedSubject ? selectedSubject.subject_id : null}
                onSubjectClick={handleSubjectChange}
              />
            </div>
          )}
          <div id="search-results">
            {Object.keys(groupedTopics).map((subjectUrlTitle) => (
              <Subject
                key={subjectUrlTitle}
                subject={subjects.find((subject) => subject.url_title === subjectUrlTitle)}
                groupedTopics={groupedTopics}
                selectedContentType={selectedContentType}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;