import React from 'react';

const FilterButton = ({ onFilterClick, buttonText }) => (
  <div className="grid-column-4">
    <div className="search-filter" id="search-filter-select">
      <button className="button-big button-white search-filter__button" id="filter-button" onClick={onFilterClick}>
        <span id="filtered-text">{ buttonText !== '' ? buttonText : 'Suchergebnisse filtern' }</span>
        <i className="icons-arrow icons-arrow--down-gray-999"></i>
      </button>
    </div>
  </div>
);

export default FilterButton;